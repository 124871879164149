<template>
  <div class="layout-wrapper">
    <BaseHeader></BaseHeader>
    <div class="main-wrapper">
      <div
        :class="{
          'left-collapse-wrapper': ifCollapse,
          'left-expand-wrapper': !ifCollapse
        }"
        v-if="!ifHideSidebar"
      >
        <BaseSidebar
          :active="activeModule"
          @on-collapse="onCollapse"
          @select="handleSelectModule"
        ></BaseSidebar>
      </div>

      <div
        :class="{
          'right-wrapper': true,
          'right-wrapper-without-footer': ifHideFooter
        }"
      >
        <div class="breadcrumb-nav-wrapper">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              :to="item.to"
              v-for="item in breadcrumbList"
              :key="item.title"
              style="@include pointer();"
            >
              {{ item.title }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div
          :class="{
            'page-wrapper': true,
            'page-wrapper-without-footer': ifHideFooter,
            'hide-padding': ifHidePadding
          }"
        >
          <router-view
            ref="routerViewRef"
            :key="$route.fullPath"
            @setBreadcrumbList="setBreadcrumbList"
          ></router-view>
        </div>
        <div class="footer-wrapper" v-if="!ifHideFooter">
          <BaseFooter></BaseFooter>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import MODULE from "../../constant/router/module-name";
import BaseHeader from "./BaseHeader.vue";
import BaseSidebar from "./BaseSidebar.vue";
import BaseFooter from "./BaseFooter.vue";

const route = useRoute();
const router = useRouter();

const routerViewRef = ref(null);
// 页面切换时自动滚动到顶部
watch(
  route,
  (route, oldRoute) => {
    nextTick(() => {
      document.getElementsByClassName("right-wrapper")[0].scrollTop = 0;
    });
  },
  {
    immediate: true,
    deep: true
  }
);

//控制左侧菜单是否展开
const ifCollapse = ref(false);
const onCollapse = (_ifCollapse) => {
  ifCollapse.value = _ifCollapse;
};

// 控制左侧菜单是否隐藏
const ifHideSidebar = ref(false);
watch(
  route,
  (route, oldRoute) => {
    ifHideSidebar.value = route.meta.ifHideSidebar;
  },
  { immediate: true, deep: true }
);

// 控制左侧高亮
const activeModule = ref(null);
watch(
  route,
  (route, oldRoute) => {
    console.log("route", route);
    activeModule.value = route.meta.module;
  },
  { immediate: true, deep: true }
);

// 选择菜单项后
const handleSelectModule = (index) => {
  console.log("select", index);
  switch (index) {
    case MODULE.HOME:
      router.push({ name: "HomeIndex" });
      break;
    case MODULE.TEXT_LIBRARY:
      router.push({ name: "TextLibraryIndex" });
      break;
    case MODULE.ANALYSIS:
      router.push({ name: "AnalysisIndex" });
      break;
    case MODULE.ANALYSIS_CONFIG:
      router.push({ name: "AnalysisConfigIndex" });
      break;
    case MODULE.TEMPLATE_REPOSITORY:
      router.push({ name: "TemplateRepositoryIndex" });
      break;
    case MODULE.RECYCLE_BIN:
      router.push({ name: "RecycleBinIndex" });
      break;
    default:
      return;
  }
};
//面包屑
const breadcrumbList = ref([
  {
    title: "首页",
    to: {
      name: "HomeIndex",
      query: {}
    }
  }
]);
const setBreadcrumbList = (_breadcrumbList) => {
  breadcrumbList.value = _breadcrumbList;
};

const ifHidePadding = ref(false);
watch(
  route,
  (route, oldRoute) => {
    ifHidePadding.value = route.meta.ifHidePadding;
  },
  { immediate: true, deep: true }
);

// 页脚
const ifHideFooter = ref(true);
watch(
  route,
  (route, oldRoute) => {
    ifHideFooter.value = route.meta.ifHideFooter;
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" scoped>
.layout-wrapper {
  .main-wrapper {
    height: $main-height;
    max-height: $main-height;
    display: flex;

    .left-wrapper {
      height: 100%;
      background: $main-background;
    }
    .left-collapse-wrapper {
      @extend .left-wrapper;
      width: $sidebar-collapse-width;
    }
    .left-expand-wrapper {
      @extend .left-wrapper;
      width: $sidebar-expand-width;
    }
    .right-wrapper {
      background: $main-background;

      height: 100%;
      flex: 1;
      overflow: auto;

      padding: {
        top: 10px;
        left: $main-page-wrapper-padding;
        right: $main-page-wrapper-padding;
      }
      .breadcrumb-nav-wrapper {
        height: $main-breadcrumb-nav-height;
      }
      .page-wrapper {
        padding: 20px;
        min-height: $main-page-height;
        background: $main-page-background;
      }
      .page-wrapper-without-footer {
        min-height: $main-page-height-without-footer;
      }
      .hide-padding {
        padding: 0px;
      }
      .footer-wrapper {
        height: $main-footer-height;
      }
    }
    .right-wrapper-without-footer {
      padding: 10px 20px 20px;
    }
  }
}
</style>
