<template>
  <div class="sidebar-wrapper">
    <el-menu
      :default-active="active"
      class="sidebar-menu"
      :collapse-transition="false"
      :collapse="ifCollapse"
      @select="handleSelectMenuItem"
    >
      <el-menu-item
        v-for="menuItem in menuItemList"
        :key="menuItem.name"
        :index="menuItem.name"
        class="sidebar-menu-item"
      >
        <i :class="['iconfont', menuItem.icon, 'icon']"></i>
        <template #title>
          {{ menuItem.label }}
        </template>
      </el-menu-item>
      <!-- <el-button
        v-show="!ifCollapse"
        link
        class="collapse-btn"
        @click="handleClickCollapse"
      >
        <i class="iconfont icon-a-129-shouqi_zhankai"></i>
      </el-button> -->
      <el-button link class="collapse-btn" @click="handleClickCollapse">
        <i
          :class="[
            'iconfont',
            ifCollapse
              ? 'icon-a-128-zhankai_shouqi'
              : 'icon-a-129-shouqi_zhankai'
          ]"
        ></i>
      </el-button>
    </el-menu>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps } from "vue";
import MODULE from "@/constant/router/module-name";
defineProps({
  active: String
});

const emits = defineEmits(["onCollapse", "select"]);

//菜单
const menuItemList = ref([
  {
    label: "首页",
    name: MODULE.HOME,
    icon: "icon-shouye"
  },
  {
    label: "文本库",
    name: MODULE.TEXT_LIBRARY,
    icon: "icon-textLibrary"
  },
  {
    label: "分析",
    name: MODULE.ANALYSIS,
    icon: "icon-fenxi"
  },
  {
    label: "配置",
    name: MODULE.ANALYSIS_CONFIG,
    icon: "icon-setting"
  },
  // {
  //   label: "模板库",
  //   name: MODULE.TEMPLATE_REPOSITORY,
  //   icon: "icon-mobanku"
  // },
  {
    label: "回收站",
    name: MODULE.RECYCLE_BIN,
    icon: "icon-shanchu1"
  }
]);
const handleSelectMenuItem = (index) => {
  emits("select", index);
};

const ifCollapse = ref(false);
//点击展开/收缩
const handleClickCollapse = () => {
  ifCollapse.value = !ifCollapse.value;
  emits("on-collapse", ifCollapse.value);
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  height: 100%;

  .sidebar-menu {
    height: 100%;
    .collapse-btn {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
    .sidebar-menu-item {
      .icon {
        margin-right: 16px;
      }
    }
    .el-menu-item {
      font-size: 14px;
    }
  }
}
</style>
